import Vue from 'vue'
import VueRouter from 'vue-router'
import {checkLogin} from "@/utils/api";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('@/views/Index'),
    children: [
      {
        path: 'manage',
        name: '产业管理',
        alias: '/',
        meta: {keepAlive: true},
        component: resolve => require(["@/views/manage/Index"], resolve)
      },
      {
        path: 'camera',
        name: '视频监控',
        component: resolve => require(["@/views/camera/Index.vue"], resolve),
        children: [
          {
            path: 'list',
            name: '视频列表',
            component: resolve => require(["@/views/camera/List"], resolve)
          },
          {
            path: 'detail',
            name: '视频详情',
            component: resolve => require(["@/views/camera/Detail"], resolve)
          }
        ]
      },
      {
        path: 'cameraList',
        name: '视频监控',
        component: resolve => require(["@/views/camera/List.vue"], resolve),
      },
      {
        path: 'pondWater',
        name: '塘水监测',
        component: resolve => require(["@/views/pondWater/Index"], resolve)
      },
      {
        path: 'tailWater',
        name: '尾水监测',
        component: resolve => require(["@/views/tailWater/Index"], resolve)
      },
      {
        path: 'weather',
        name: '气象监测',
        component: resolve => require(["@/views/weather/Index"], resolve)
      },
      {
        path: 'waterLevel',
        name: '水位监测',
        component: resolve => require(["@/views/waterLevel/Index"], resolve)
      },
      {
        path: 'dataStatistics',
        name: '数据统计',
        component: resolve => require(["@/views/dataStatistics/Index"], resolve)
      },
      {
        path: 'monitorData',
        name: '监测数据',
        component: resolve => require(["@/views/monitorData/Index"], resolve)
      }
    ]
  },
  {
    path: '/warningList',
    name: '预警列表',
    component: resolve => require(["@/views/warningList/Index"], resolve)
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('@/views/login/Index')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/login') {
    if (localStorage.getItem('token')) {
      next('/')
      return
    }
    next()
  } else {
    if (localStorage.getItem('token') === null) {
      next('/login')
    } else {
      const isLogin = await checkLogin()
      if (!isLogin) {
        localStorage.removeItem('token')
        next('/login')
        return
      }
      next()
    }
  }
})

export default router;
