import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    mapinfo: null,
    // 天气信息
    weather: null,
    // 当前显示公司主体
    companyId: 0,
    curRegion: '',
    // 经营主体中选择的镇
    curTown: '',
    // 新北区大屏数据所有的镇
    towns: [],

    // 地图设备点位列表
    deviceLocations: [],
    // 基地点位列表
    baseLocations: [],
    // 地图标记点类型 0 基地的标记点 1 设备的标记点
    markType: 0,

    // 产品类型id 显示该产品的基地点位
    curProductId: '',
    // 监控播放token
    accessToken: '',
    // 显示/隐藏地图筛选列表项
    mapFilterStatus: false,
    // 显示/隐藏地图矢量
    mapVectorStatus: true,
    // 重置视角
    mapResetView: false,
    // 重置参数
    mapResetParams: false,
    // 地图刷新点位 0 1 有变化就触发
    mapRefresh: 0,
    // 地图只显示数字渔场的点位
    mapIsDigital: '',
    // 产品类型图标
    productTypeIcons: [],
    // 视图是否聚焦在点位上
    showMarkersView: false,
    // 只显示尾水的图标
    showTailWaterMarker: false,
    // 监测数据指标列表
    elementMapping: JSON.parse(localStorage.getItem('elementMapping')) | [],
    // 监测数据参数指标
    mappingMsg: {}
  },
  getters: {
    // mapinfo: state => state.mapinfo
  },
  mutations: {
    mapinfo(state, mapinfo = null) {
      state.mapinfo = mapinfo
    },
    setWeatherInfo(state, value) {
      state.weather = value
    },
    setCompanyId(state, value) {
      state.companyId = value
    },
    setCurRegion(state, value) {
      state.curRegion = value
    },
    setTowns(state, value) {
      state.towns = value
    },
    setCurTown(state, value) {
      state.curTown = value
    },
    setDeviceLocations(state, value) {
      state.deviceLocations = value
    },
    setBaseLocations(state, value) {
      state.baseLocations = value
    },
    setMarkType(state, value) {
      state.markType = value
    },
    setCurProductId(state, value) {
      state.curProductId = value
    },
    setAccessToken(state, value) {
      state.accessToken = value
    },
    setMapFilterStatus(state, value) {
      state.mapFilterStatus = value
    },
    setMapVectorStatus(state, value) {
      state.mapVectorStatus = value
    },
    setMapResetView(state, value) {
      state.mapResetView = value
    },
    setMapResetParams(state, value) {
      state.mapResetParams = value
    },
    setMapIsDigital(state, value) {
      state.mapIsDigital = value
    },
    setMapRefresh(state, value) {
      state.mapRefresh = value
    },
    setProductTypeIcons(state, value) {
      state.productTypeIcons = value
    },
    setShowMarkersView(state, value) {
      state.showMarkersView = value
    },
    setShowTailWaterMarker(state, value) {
      state.showTailWaterMarker = value
    },
    setElementMapping(state, value) {
      state.elementMapping = value
    },
    setMappingMsg(state, value) {
      state.mappingMsg = value
    }
  },
  actions: {
    setWeatherInfo({commit}, item) {
      console.log('value', item)
      commit('setWeatherInfo', item)
    },
    setCompanyIdAction({commit}, item) {
      commit('setCompanyId', item)
    },
    setCurRegionAction({commit}, item) {
      commit('setCurRegion', item)
    },
    setTownsAction({commit}, item) {
      commit('setTowns', item)
    },
    setCurTownAction({commit}, item) {
      commit('setCurTown', item)
    },
    setDeviceLocationsAction({commit}, item) {
      commit('setDeviceLocations', item)
    },
    setBaseLocationsAction({commit}, item) {
      commit('setBaseLocations', item)
    },
    setMarkTypeAction({commit}, item) {
      commit('setMarkType', item)
    },
    setCurProductIdAction({commit}, item) {
      commit('setCurProductId', item)
    },
    setAccessTokenAction({commit}, item) {
      commit('setAccessToken', item)
    },
    setMapFilterStatusAction({commit}, item) {
      commit('setMapFilterStatus', item)
    },
    setMapVectorStatusAction({commit}, item) {
      commit('setMapVectorStatus', item)
    },
    setMapResetViewAction({commit}, item) {
      commit('setMapResetView', item)
    },
    setMapResetParamsAction({commit}, item) {
      commit('setMapResetParams', item)
    },
    setMapIsDigitalAction({commit}, item) {
      commit('setMapIsDigital', item)
    },
    setMapRefreshAction({commit}, item) {
      commit('setMapRefresh', item)
    },
    setProductTypeIconsAction({commit}, item) {
      commit('setProductTypeIcons', item)
    },
    setShowMarkersViewAction({commit}, item) {
      commit('setShowMarkersView', item)
    },
    setShowTailWaterMarkerAction({commit}, item) {
      commit('setShowTailWaterMarker', item)
    },
    setElementMappingAction({commit}, item) {
      localStorage.setItem('elementMapping', JSON.stringify(item))
      commit('setElementMapping', item)
    },
    setMappingMsgAction({commit}, item) {
      commit('setMappingMsg', item)
    }
  }
})

