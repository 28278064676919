import api from './request'
import {Message} from 'element-ui'
import { toCamelCase } from '@/utils/common'
import store from '@/store'

/**
 * get 请求
 * @param path
 * @returns {Promise}
 */
export const getData = async (path, params = {}, apiPrefix = 'api/') => {
  const res = await api.get(apiPrefix + path, params)
  if (res.code == 200) {
    return res.data
  } else {
    if (res.code == 1001) {
      Message.error(res.msg)
    }
    console.log(`${path}返回数据异常！`)
  }
}
/**
 * post 请求
 * @param path
 * @param params
 * @returns {Promise}
 */
export const postData = async (path, params = {}, apiPrefix = 'api/') => {
  const res = await api.post(apiPrefix + path, params)
  console.log('res', res)
  const data = res.data
  if (data.code == 200) {
    return data.data
  } else {
    console.log(`${path}返回数据异常！`)
  }
}

export const getCameraUrl = async (cameraId) => {
  const data = await getData(`HkCamera/getHkCameraAddress/${cameraId}`)
  if (data && data.playUrl) {
    return data.playUrl
  }
  return ''
}

export const getHkCameraToken = () => {
  return api.get('/api/getHkCameraToken')
}
export const upload = (formdata) => {
  return api.post('/api/uploadScreenShot?cameraId=' + formdata.get('cameraId'), formdata, {
    // 表示上传的是文件,而不是普通的表单数据
    'Content-Type': 'multipart/form-data'
  })
}

/**
 * 校验登录态
 * 如果非登录态,则返回false,否则返回true
 */
export const checkLogin = async () => {
  const res = await api.get('/api/screenUser/isLogin', {
    // token: localStorage.getItem('token')
  })
  if (res.code == 200) {
    return res.data
  } else {
    return false
  }
}

export const getElementMappingList = async () => {
  const data = await getData('getElementMapping')
  const elementMapping = data
  const mappingMsg = {}
  elementMapping.forEach(v => {
    const {targetValue, target} = v
    if (targetValue.includes("≤")) {
      v.symbol = '≤'
    } else if (targetValue.includes("≥")) {
      v.symbol = '≥'
    } else if (targetValue.includes("-") || targetValue.includes("~")) {
      v.symbol = '-'
    } else if (targetValue.includes(">")) {
      v.symbol = '>'
    } else if (targetValue.includes("<")) {
      v.symbol = '<'
    } else {
      if (target === 'orp') {
        v.symbol = 'orp'
      }
    }
    if (targetValue.includes("-")) {
      const strArr = targetValue.split('-')
      v.value = strArr[0].replace(/mg\/L|℃/g, "")
      v.value2 = strArr[1].replace(/mg\/L|℃/g, "")
    } else if (targetValue.includes("~")) {
      const strArr = targetValue.split('~')
      v.value = strArr[0].replace(/mg\/L|℃/g, "")
      v.value2 = strArr[1].replace(/mg\/L|℃/g, "")
    } else {
      v.value = targetValue.replace(/mg\/L|℃|<|>|≤|≥/g, "")
    }

    // 超标值
    const key = toCamelCase(target)
    mappingMsg[key] = targetValue
  })
  console.log('elementMapping', elementMapping)
  console.log('mappingMsg', mappingMsg)
  return {
    elementMapping,
    mappingMsg
  }
}

