
/**
 * @name: handleCutZero
 * @description:  去掉double类型小数点后面多余的0
 参数：old 要处理的字符串或double
 返回值：newStr 没有多余零的小数或字符串
 * @param {*} num
 * @return {*}num
 */
export function parseFloatDesc(num) {
    num = Number(num)
    return parseFloat(num);
}


export function toCamelCase(str) {
  return str.split('_').map((word, index) => {
    if (index === 0) {
      // 对于第一个单词，保持其原样（如果它已经是小写的话，或者你可以根据需要处理它）
      // 这里我们假设输入字符串的第一个单词已经是正确的格式
      return word
    } else {
      // 对于其他单词，将首字母大写，其余字母小写
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  }).join('')
}

/**
 * 获取超标数据
 * 0 未超标 1 偏高 1 偏低
 */
export function getSuperMappingData (data, elementMapping, suffix = '') {
  if (!data) {
    return
  }
  const superMapping = {}
  Object.keys(data).forEach(k => {
    const superK = k + suffix
    const msg = elementMapping.find(v => v.target === k)
    if (msg) {
      switch (msg.symbol) {
        case '≤':
          if (Number(data[k]) <= Number(msg.value)) {
            superMapping[superK] = 0
          } else {
            superMapping[superK] = 1
          }
          break
        case '<':
          if (Number(data[k]) < Number(msg.value)) {
            superMapping[superK] = 0
          } else {
            superMapping[superK] = 1
          }
          break
        case '≥':
          if (Number(data[k]) >= Number(msg.value)) {
            superMapping[superK] = 0
          } else {
            superMapping[superK] = 2
          }
          break
        case '>':
          if (Number(data[k]) > Number(msg.value)) {
            superMapping[superK] = 0
          } else {
            superMapping[superK] = 2
          }
          break
        case '-':
          if (Number(data[k]) < Number(msg.value)) {
            superMapping[superK] = 2
          } else if (Number(data[k]) > Number(msg.value2)) {
            superMapping[superK] = 1
          } else {
            superMapping[superK] = 0
          }
          break
        case 'orp':
          if (data[k] !== msg.value) {
            superMapping[superK] = 1
          } else {
            superMapping[superK] = 0
          }
          break
      }
    }
  })
  return superMapping
}
